import React, {useEffect} from 'react';
import {Helmet} from 'react-helmet';
import './About.css';
import Contact from './Contact'; // Importation du composant Contact
import {initializeEmailJS} from '../services/emailService';
import Footer from "./Footer";
import Skills from "./Skills";
import Text from "./Text";
import AnimatedText from "./AnimatedText";
import FixedMenu from "./FixedMenu";
import Portfolio from "./Portfolio"
import GlowAbout from "./GlowTitle/GlowAbout";
import GlowPortfolio from "./GlowTitle/GlowPortfolio";
import GlowContact from "./GlowTitle/GlowContact";


function About() {

    useEffect(() => {
        initializeEmailJS(); // Initialiser EmailJS ici

    }, []);

    return (
        <div className="about-container">
            {/* Logo et Texte animé */}

            <AnimatedText />
            {/* sphere*/}
            <div className="sphere">
                <div className="bg">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
            {/* Menu de navigation fixe */}
            <FixedMenu />

            {/* Sections à propos */}
            <section id="about" className="section">
                <Helmet>
                    <link rel="canonical" href="https://aurorebudzik.fr/#about"/>
                </Helmet>
                <GlowAbout />
                <Text />
                <Skills />

            </section>




            {/* Section Portfolio */}
            <Helmet>
                <link rel="canonical" href="https://aurorebudzik.fr/#portfolio"/>
            </Helmet>

            <section id="portfolio" className="section">
                <GlowPortfolio />
               <Portfolio />
            </section>

            {/* Section Contact */}

             <section id="contact" className="section-contact">
                <Helmet>
                    <link rel="canonical" href="https://aurorebudzik.fr/#contact" />
                </Helmet>
                <GlowContact />
                <Contact/>

            </section>
            {/*  Footer */}
            <Footer/>
        </div>

    );

}


export default About;

