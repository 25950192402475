import React, { useEffect } from 'react';
import { gsap } from 'gsap';
import './Skills.css'; // Importez les styles CSS associés

const Skills = () => {
    useEffect(() => {
        const container = document.querySelector('.skills-container');

        // Rotation infinie
        gsap.to(container, {
            rotation: 360,
            duration: 15,
            repeat: -1,
            ease: "linear"
        });

        // Animation individuelle des compétences
        const skills = document.querySelectorAll('.skill');
        skills.forEach((skill, index) => {
            gsap.fromTo(
                skill,
                { scale: 0, opacity: 0 },
                {
                    scale: 1,
                    opacity: 1,
                    duration: 1,
                    delay: index * 0.3,
                    ease: "back.out(1.7)"
                }
            );
        });

        // Animation du texte central
        const centralText = document.querySelector('.central-text');
        gsap.fromTo(
            centralText,
            { scale: 0, opacity: 0 },
            {
                scale: 1.5,
                opacity: 1,
                duration: 1.5,
                ease: "bounce.out",
                repeat: -1,
                yoyo: true
            }
        );
    }, []);

    return (
        <div className="skills-css">
            <div className="skills-container">
                <div className="central-text">Mes Compétences</div>
                {[
                    "HTML", "CSS", "JavaScript", "PHP", "Symfony", "Angular",
                    "React", "Illustrator", "Photoshop", "WordPress", "PrestaShop", "SEO"
                ].map((skill, index) => (
                    <div
                        key={index}
                        className="skill"
                        style={{ transform: `rotate(${index * 30}deg) translateX(200px)` }}
                    >
                        {skill}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Skills;
