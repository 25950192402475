import React, { useEffect } from "react";
import "./Portfolio.css";
import FelicieColin from '../img/felicie-colin.webp';
import DelConcept from '../img/delconcept.fr.webp'
import Mic from '../img/made-in-calais.webp';
import KJ from '../img/maitre-kevin-jurion.webp';
import Shoredesign from '../img/shore-design.webp';
import Jsdebarras from '../img/js-debarras.webp';
import Falbala from '../img/falbalasfabala.webp';
import Mamsweb from '../img/mamsweb.webp';
import ABPortfolio from '../img/portfolio-aurore-budzik.webp';
import Series from '../img/serie.webp';
import Interhop from '../img/interhop.webp';
import ABC from '../img/abc.webp';
import Snapfaces from '../img/snapfaces.webp';
const Portfolio = () => {
    useEffect(() => {
        const bullets = document.querySelectorAll(".bullet");
        const projectCards = document.querySelectorAll(".project-card");
        let currentSlide = 0;

        const changeSlide = (slideIndex) => {
            projectCards.forEach((card) => card.classList.remove("active"));
            projectCards[slideIndex].classList.add("active");
            currentSlide = slideIndex;

            bullets.forEach((bullet, index) => {
                bullet.classList.toggle("active", index === slideIndex);
            });
        };

        bullets.forEach((bullet, index) => {
            bullet.addEventListener("click", () => changeSlide(index));
        });

        changeSlide(0);

        return () => {
            bullets.forEach((bullet) => bullet.removeEventListener("click", () => {}));
        };
    }, []);

    return (
        <div className="portfolio">

            <div className="projects-container">
                <div className="projects-slider">
                    <div className="project-card">
                        <img src={FelicieColin} alt="site internet de félicie colin"
                             className="project-image"></img>
                        <div className="project-info">
                            <ul>
                                <li><a href="https://feliciecolin.com" className="styled-link"
                                       links="Félicie Colin" target="_blank">Félicie Colin </a></li>
                            </ul>
                            <p>WordPress</p>
                        </div>
                    </div>
                    <div className="project-card">
                        <img src={DelConcept} alt="site internet de Del'Concept" className="project-image"></img>
                        <div className="project-info">
                            <ul>
                                <li><a href="https://delconcept.fr" className="styled-link"
                                       links="Del'Concept" target="_blank">Del'Concept</a></li>
                            </ul>
                            <p>WordPress</p>
                        </div>
                    </div>
                    <div className="project-card">
                        <img src={Mic} alt="Site internet" className="project-image"></img>
                        <div className="project-info">
                            <ul>
                                <li><a href="https://madeincalais.fr" className="styled-link"
                                       links="Made in Calais" target="_blank">Made in Calais</a></li>
                            </ul>
                            <p>WordPress</p>
                        </div>
                    </div>
                    <div className="project-card">
                        <img src={KJ} alt="Site internet de Maître Jurion Kévin" className="project-image"></img>
                        <div className="project-info">
                            <ul>
                                <li><a href="https://maitrekevinjurion.fr" className="styled-link"
                                       links="Maître Jurion" target="_blank">Maître Jurion</a></li>
                            </ul>
                            <p>WordPress</p>
                        </div>
                    </div>
                    <div className="project-card">
                        <img src={Shoredesign} alt="Site internet de Shore" className="project-image"></img>
                        <div className="project-info">
                            <ul>
                                <li><a href="https://shoredesign.fr" className="styled-link" links="Shore"
                                       target="_blank">Shore</a></li>
                            </ul>
                            <p>WordPress</p>
                        </div>
                    </div>
                    <div className="project-card">
                        <img src={Jsdebarras} alt="Site internet de JS DEBARRAS" className="project-image"></img>
                        <div className="project-info">
                            <ul>
                                <li><a href="https://jsdebarras.com" className="styled-link"
                                       links="JS DEBARRAS" target="_blank">JS DEBARRAS</a></li>
                            </ul>
                            <p>WordPress</p>
                        </div>
                    </div>
                    <div className="project-card">
                        <img src={Falbala} alt="Site internet de Fabalas Fabala" className="project-image"></img>
                        <div className="project-info">
                            <ul>
                                <li><a href="https://falbalasfabala.fr" className="styled-link"
                                       links="FALBALAS FABALA" target="_blank">FALBALAS FABALA</a></li>
                            </ul>
                            <p>WordPress (en cours...)</p>
                        </div>
                    </div>
                    <div className="project-card">
                        <img src={Mamsweb} alt="Site internet de Mamsweb" className="project-image"></img>
                        <div className="project-info">
                            <ul>
                                <li><a href="https://mamsweb.fr" className="styled-link" links="MAMSWEB"
                                       target="_blank">MAMSWEB</a></li>
                            </ul>
                            <p>Wordpress</p>
                        </div>
                    </div>
                    <div className="project-card">
                        <img src={ABPortfolio} alt="Portfolio Aurore Budzik" className="project-image"></img>
                        <div className="project-info">
                            <ul>
                                <li><a href="https://github.com/abdzk/pba" className="styled-link"
                                       links="Mon portfolio (Github)" target="_blank">Mon portfolio
                                    (Github)</a></li>
                            </ul>
                            <p>JavaScript - React</p>
                        </div>
                    </div>
                    <div className="project-card">
                        <img src={Series} alt="Application Symfony" className="project-image"></img>
                        <div className="project-info">
                            <ul>
                                <li><a href="https://github.com/abdzk/series-symfony"
                                       className="styled-link" links="Séries (Github)" target="_blank">Séries
                                    (Github)</a></li>
                            </ul>
                            <p>PHP-Symfony</p>
                        </div>
                    </div>
                    <div className="project-card">
                        <img src={Interhop} alt="Site internet Interhop version test"
                             className="project-image"></img>
                        <div className="project-info">
                            <ul>
                                <li><a href="https://easyappointments-test.interhop.org/"
                                       className="styled-link" links="Association Interhop (stage)"
                                       target="_blank">Association Interhop (stage)</a></li>
                            </ul>
                            <p>Application Open Source : EASYAPPOINTMENT <br/>
                                CodeIgniter-PHP-JQUERY-BOOSTRAP<br/>
                                <a href="https://interhop.org/2022/12/14/stagiaire2-ea-post-1">En savoir
                                    plus</a>
                            </p>
                        </div>
                    </div>
                    <div className="project-card">
                        <img src={ABC} alt="Projet Perso" className="project-image"></img>
                        <div className="project-info">
                            <ul>
                                <li><a href="https://github.com/abdzk/ABCDaire" className="styled-link"
                                       links="ABCéDaire" target="_blank">ABCéDaire (Github)</a></li>
                            </ul>
                            <p>Symfony-Php-Boostrap (en cours) <br/>
                            </p>
                        </div>
                    </div>
                    <div className="project-card">
                        <img src={Snapfaces} alt="Projet perso angular" className="project-image"></img>
                        <div className="project-info">
                            <ul>
                                <li><a href="https://github.com/abdzk/facesnap" className="styled-link"
                                       links="Snap Face (Github)" target="_blank">Snap Face (Github)</a>
                                </li>
                            </ul>
                            <p>TypeScript-Angular <br/>
                            </p>
                        </div>
                    </div>

                </div>
                <div className="project-navigation">
                    <ul>
                        <li className="bullet" data-slide="0"></li>
                        <li className="bullet" data-slide="1"></li>
                        <li className="bullet" data-slide="2"></li>
                        <li className="bullet" data-slide="3"></li>
                        <li className="bullet" data-slide="4"></li>
                        <li className="bullet" data-slide="5"></li>
                        <li className="bullet" data-slide="6"></li>
                        <li className="bullet" data-slide="7"></li>
                        <li className="bullet" data-slide="8"></li>
                        <li className="bullet" data-slide="9"></li>
                        <li className="bullet" data-slide="10"></li>
                        <li className="bullet" data-slide="11"></li>
                        <li className="bullet" data-slide="12"></li>

                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Portfolio;