import React from "react";
import "./GlowAbout.css";

const GlowAbout = () => {
    return (
        <div className="header-text-about" aria-hidden="true">
            <br />
            <span className="glow-filter-about" data-text="Bienvenue sur mon portfolio">
                Bienvenue sur mon portfolio
            </span>
            {/* SVG pour effet Glow */}
            <svg width="0" height="0" style={{ position: "absolute" }}>
                <filter id="glow-4" x="-50%" y="-50%" width="200%" height="200%">
                    <feGaussianBlur stdDeviation="4" result="coloredBlur" />
                    <feMerge>
                        <feMergeNode in="coloredBlur" />
                        <feMergeNode in="SourceGraphic" />
                    </feMerge>
                </filter>
            </svg>
        </div>
    );
};

export default GlowAbout;

