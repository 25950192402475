import React from 'react';
import './FixedMenu.css'; // Assurez-vous de lier le fichier CSS

const FixedMenu = () => {
    return (
        <nav className="fixed-menu">
            <ul>
                <li><a href="#about" links="A propos">À propos</a></li>
                <li><a href="#portfolio" links="Portfolio">Portfolio</a></li>
                <li><a href="#contact" links="Contact">Contact</a></li>
            </ul>
        </nav>
    );
};

export default FixedMenu;
