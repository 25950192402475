import React, { useEffect, useRef } from "react";
import "./AnimatedText.css";

const AnimatedText = ({ text = "MAMSWEB" }) => {
    const containerRef = useRef(null); // Référence au conteneur des lettres

    useEffect(() => {
        const container = containerRef.current; // Conteneur des lettres
        if (!container) return;

        // Supprime les anciens <span> au cas où le texte change
        container.innerHTML = "";

        // Crée des éléments <span> pour chaque lettre
        text.split("").forEach((char) => {
            const span = document.createElement("span"); // Crée un <span> pour chaque lettre
            span.className = "letter"; // Ajoute la classe 'letter'
            span.innerHTML = char === " " ? "&nbsp;" : char; // Utilise &nbsp; pour les espaces
            container.appendChild(span); // Ajoute le <span> au conteneur
        });

        const letters = container.querySelectorAll(".letter"); // Sélectionne toutes les lettres
        const totalLetters = letters.length; // Nombre total de lettres
        const delayIncrement = 100; // Incrément de délai en millisecondes

        // Fonction d'accélération/décélération (ease-in-out)
        function easeInOutQuart(t) {
            return t < 0.5 ? 8 * t * t * t * t : 1 - 8 * (--t) * t * t * t;
        }

        // Fonction pour animer les lettres
        function animateLetters(forward = true) {
            letters.forEach((letter, index) => {
                // Normalise l'index pour un effet symétrique
                const normalizedIndex = Math.max(index, totalLetters - 1 - index) / (totalLetters - 1);
                const easedDelay = easeInOutQuart(normalizedIndex); // Applique la courbe d'animation
                const delay = easedDelay * (totalLetters - 1) * delayIncrement;

                setTimeout(() => {
                    letter.style.setProperty("--wght", forward ? 700 : 100); // Poids de la police
                    letter.style.setProperty("--wdth", forward ? 400 : 150); // Largeur de la police
                    letter.style.setProperty("--opacity", forward ? 1 : 0.25); // Opacité
                    letter.style.setProperty("--letter-spacing", forward ? "0.05em" : "0em"); // Espacement
                }, delay);
            });

            // Relance l'animation après un délai
            setTimeout(() => animateLetters(!forward), 4000);
        }

        animateLetters(); // Démarre l'animation

        // Nettoyage si le composant est démonté
        return () => {
            container.innerHTML = "";
        };
    }, [text]); // Réexécute si le texte change

    return  ( <div className="logo-mamsweb">
        <div className="animated-text" ref={containerRef} id="animatedText"></div>
    </div>)
};

export default AnimatedText;
