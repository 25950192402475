import React from "react";
import "./text.css";

const Text = () => {
    return (
        <section className="text-section">
            {/* Conteneur de contenu */}
            <div className="ab-container">
                <div className="about-content">
                    <p className="p-about">
                        Bonjour, je suis <strong>Aurore Budzik</strong>, développeuse web freelance spécialisée dans
                        la création de sites interactifs, réactifs et sur-mesure.
                    </p>

                    <p className="p-about">
                        Après plusieurs années d'expérience dans le secteur de la restauration, j'ai choisi de réorienter
                        ma carrière vers le développement web, une passion qui m'anime depuis longtemps. J'ai ainsi
                        suivi une formation intensive à l'école informatique ENI, où j'ai acquis des compétences solides
                        en <strong>HTML, CSS, PHP</strong> et <strong>JavaScript</strong>, ainsi qu'une maîtrise des
                        frameworks populaires tels que <strong>Symfony</strong> et <strong>Angular</strong>.
                    </p>

                    <p className="p-about">
                        Mon parcours m'a permis de développer également des compétences essentielles en gestion de
                        projet et en travail d'équipe, grâce aux nombreux projets de groupe réalisés et à un stage
                        en entreprise enrichissant.
                    </p>

                    <h3 className="h3-about">MAMSWEB : mon entreprise dédiée à la création de sites web</h3>
                    <p className="p-about">
                        Pour concrétiser mon ambition, j'ai fondé <strong>MAMSWEB</strong>, une entreprise spécialisée
                        dans la création de sites vitrines, e-commerce et professionnels. J'y utilise principalement
                        les <strong>CMS WordPress</strong> et <strong>PrestaShop</strong> pour offrir des solutions
                        performantes, mais je suis également en mesure de travailler avec des frameworks tels que
                        <strong>Symfony</strong> ou <strong>Angular</strong> pour des projets nécessitant un
                        développement sur-mesure.
                    </p>

                    <h3 className="h3-about">Pourquoi collaborer avec moi ?</h3>
                    <ul>
                        <li>Création de sites web réactifs, modernes et personnalisés</li>
                        <li>Expertise en CMS <strong>WordPress</strong> et <strong>PrestaShop</strong></li>
                        <li>Capacité à développer avec des frameworks robustes pour des solutions sur-mesure</li>
                        <li>Sens de l'écoute, créativité et implication à chaque étape de votre projet</li>
                    </ul>

                    <p className="p-about">
                        Je serais ravie d'échanger avec vous pour discuter de vos besoins et contribuer au succès de
                        vos projets. <strong>N'hésitez pas à me contacter</strong> pour toute demande ou pour un devis
                        personnalisé !
                    </p>
                </div>
            </div>
        </section>
    );
};

export default Text;
