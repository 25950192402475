import React from "react";
import "./GlowPortfolio.css";

const GlowPortfolio = () => {
    return (
        <div className="header-text-portfolio" aria-hidden="true">
            <br />
            <span className="glow-filter-portfolio" data-text="Mes Créations">
                Mes Créations
            </span>
            {/* SVG pour effet Glow */}
            <svg width="0" height="0" style={{ position: "absolute" }}>
                <filter id="glow-4" x="-50%" y="-50%" width="200%" height="200%">
                    <feGaussianBlur stdDeviation="4" result="coloredBlur" />
                    <feMerge>
                        <feMergeNode in="coloredBlur" />
                        <feMergeNode in="SourceGraphic" />
                    </feMerge>
                </filter>
            </svg>
        </div>
    );
};

export default GlowPortfolio;